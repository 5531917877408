import React, { } from "react";

class Slider extends React.Component{
  render() {
  return (
    <section className="head_section layout_padding3">
  <div className="container-fluid">
    <div className="rowbg">
      <div className="col-md-13 offset-md-1">
        <div id="textSlider" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <h3> Empowering Your OET Journey</h3>
              <p className="head_text">Unlock your potential with YesEd Academy's specialized OET training. Elevate your language skills and gain confidence in real healthcare situations.</p>
            </div>
            <div className="carousel-item">
              <h3>Master the Language of Care</h3>
              <p className="head_text">Join YesEd Academy for OET success. Our expert instructors and healthcare-focused approach equip you for effective, compassionate communication</p>
            </div>
            <div className="carousel-item">
              <h3>Your Success, Our Priority</h3>
              <p className="head_text">At YesEd Academy, OET success isn't just a goal; it's our unwavering commitment. Experience personalized learning, practical simulations, and comprehensive resources tailored to excel in healthcare.</p>
            </div>
            <div className="carousel-item">
              <h3>Where Dreams Take Shape</h3>
              <p className="head_text">Dreaming of a thriving healthcare career abroad? Turn your aspirations into reality with YesEd Academy. Step into a world of immersive learning, constant support, and the tools you need to excel in the OET</p>
            </div>
            <div className="carousel-item">
              <h3>Beyond Language Proficiency</h3>
              <p className="head_text">Achieve OET success with YesEd Academy. Master the language of your profession and gain a competitive edge through a deep understanding of healthcare contexts</p>
            </div>
            <div className="carousel-item">
              <p className="head_text">Embark on your journey to OET success with YesEd Academy. Experience the difference of tailored training, expert guidance, and a community that believes in your potential</p>
              {/*<h3> Enroll Now <button >Start Your Journey</button></h3>*/}
              <h3> Enroll Now <button>Call Us</button></h3>
            </div>
          </div>
          <div className="col-md-12">
            <ol className="carousel-indicators">
              <li data-target="#textSlider" data-slide-to={0} className="active" />
              <li data-target="#textSlider" data-slide-to={1} />
              <li data-target="#textSlider" data-slide-to={2} />
              <li data-target="#textSlider" data-slide-to={3} />
              <li data-target="#textSlider" data-slide-to={4} />
              <li data-target="#textSlider" data-slide-to={5} />
            </ol>
          </div>
        </div>
      </div>
      <div className="col-md-14 px-0">
        <div className="img-box ">
          <img src="images/bg-head2.png" className="box_img" alt="about img" />
        </div>
      </div>
    </div>
  </div>
</section>

  );
}
}
export default Slider;
